
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DataService } from './data.service';
import { LoggerService } from './logger.service';

import { environment } from '../../../../environments/environment';


@Injectable({ providedIn: 'root' })


export class ApiService {

  constructor(private httpClient: HttpClient, private dataService: DataService, private logger: LoggerService) { }

  postFormDataApi(url: string, apiHeaders?: {}, apiBody?: {}) {
    const headers = new HttpHeaders(apiHeaders);
    let formData = new FormData();

    return this.httpClient.post(`${environment.api.url}${environment.api.port}/${url}`, apiBody, { headers });
  }

  // async localPostApi(url: string, body: {}): Promise<any> {
  //   try {

  //     var headers = new HttpHeaders();

  //     headers = headers.append('Content-Type', 'application/json; charset=utf-8');

  //     const response = await this.httpClient.post('https://apidev.openvibes.com:443' + url, body, { headers, responseType: 'text' }).toPromise();
  //     this.logger.log('ApiService', 'localPostApi', response, 'debug');

  //     return response;

  //   } catch (error) {
  //     this.logger.log('ApiService', 'localPostApi', error, 'error');
  //     return;
  //   }
  // }

  async postApi(url: string, body: {}): Promise<any> {
    try {

      var headers = new HttpHeaders();

      headers = headers.append('Content-Type', 'application/json; charset=utf-8');

      if (this.dataService.getProperty('authSession')) {
        headers = headers.append('authJwt', this.dataService.getProperty('authSession').authJwt);
      }

      const response = await this.httpClient.post(environment.api.url + environment.api.port + url, body, { headers, responseType: 'text' }).toPromise();
      this.logger.log('ApiService', 'postApi', response, 'debug');

      return response;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  // async localPatchApi(url: string, body: {}): Promise<any> {
  //   try {

  //     var headers = new HttpHeaders();

  //     headers = headers.append('Content-Type', 'application/json; charset=utf-8');

  //     if (this.dataService.authSession) {
  //       headers = headers.append('authJwt', this.dataService.authSession.authJwt);
  //     }

  //     if (this.dataService.profileSession) {
  //       headers = headers.append('profileJwt', this.dataService.profileSession.profileJwt);
  //     }

  //     const response = await this.httpClient.patch('http://localhost:80' + url, body, { headers, responseType: 'text' }).toPromise();
  //     this.logger.log('ApiService', 'localPatchApi', response, 'debug');

  //     return response;

  //   } catch (error) {
  //     this.logger.log('ApiService', 'localPatchApi', error, 'error');
  //     return;
  //   }
  // }

  async patchApi(url: string, body: {}): Promise<any> {
    try {

      var headers = new HttpHeaders();

      headers = headers.append('Content-Type', 'application/json; charset=utf-8');

      if (this.dataService.getProperty('authSession').authSession) {
        headers = headers.append('authJwt', this.dataService.getProperty('authSession').authJwt);
      }

      const response = await this.httpClient.patch(environment.api.url + environment.api.port + url, body, { headers, responseType: 'text' }).toPromise();
      this.logger.log('ApiService', 'patchApi', response, 'debug');

      return response;

    } catch (error) {
      this.logger.log('ApiService', 'patchApi', error, 'error');
      return;
    }
  }

  async putApi(url: string, body: {}): Promise<any> {
    try {

      var headers = new HttpHeaders();

      headers = headers.append('Content-Type', 'application/json; charset=utf-8');

      if (this.dataService.getProperty('authSession')) {
        headers = headers.append('authJwt', this.dataService.getProperty('authSession').authJwt);
      }

      const response = await this.httpClient.put(environment.api.url + environment.api.port + url, body, { headers, responseType: 'text' }).toPromise();
      this.logger.log('ApiService', 'putApi', response, 'debug');

      return response;

    } catch (error) {
      this.logger.log('ApiService', 'putApi', error, 'error');
      return;
    }
  }

  // async localGetApi(url: string, query?: string): Promise<any> {
  //   try {

  //     var headers = new HttpHeaders();

  //     headers = headers.append('Content-Type', 'application/json; charset=utf-8');

  //     if (this.dataService.authSession) {
  //       headers = headers.append('authJwt', this.dataService.authSession.authJwt);
  //     }


  //     this.dataService.setProfileSessionProperty();
  //     if (this.dataService.profileSession) {
  //       headers = headers.append('profileJwt', this.dataService.profileSession.profileJwt);
  //     }

  //     const response = await this.httpClient.get('http://localhost:80' + url + query, { headers }).toPromise();
  //     this.logger.log('ApiService', 'localGetApi', response, 'debug');

  //     return response;

  //   } catch (error) {
  //     this.logger.log('ApiService', 'localGetApi', error.error, 'error');
  //     return;
  //   }
  // }

  async getApi(url: string, query?: string): Promise<any> {
    try {

      var headers = new HttpHeaders();

      headers = headers.append('Content-Type', 'application/json; charset=utf-8');

      if (this.dataService.getProperty('authSession')) {
        headers = headers.append('authJwt', this.dataService.getProperty('authSession').authJwt);
      }

      const response = await this.httpClient.get(environment.api.url + environment.api.port + url + query, { headers }).toPromise();
      this.logger.log('ApiService', 'getApi', response, 'debug');

      return response;

    } catch (error) {
      this.logger.log('ApiService', 'getApi', error, 'error');
      return;
    }
  }

  // async localDeleteApi(url: string, query?: string): Promise<any> {
  //   try {

  //     var headers = new HttpHeaders();

  //     headers = headers.append('Content-Type', 'application/json; charset=utf-8');

  //     if (this.dataService.authSession) {
  //       headers = headers.append('authJwt', this.dataService.authSession.authJwt);
  //     }

  //     if (this.dataService.profileSession) {
  //       headers = headers.append('profileJwt', this.dataService.profileSession.profileJwt);
  //     }

  //     const response = await this.httpClient.delete('http://localhost:80' + url + query, { headers, responseType: 'text' }).toPromise();
  //     this.logger.log('ApiService', 'localDeleteApi', response, 'debug');

  //     return response;

  //   } catch (error) {
  //     console.log(error);
  //     this.logger.log('ApiService', 'localDeleteApi', error.error, 'error');
  //     return;
  //   }
  // }

  async deleteApi(url: string, query?: string): Promise<any> {
    try {

      var headers = new HttpHeaders();

      headers = headers.append('Content-Type', 'application/json; charset=utf-8');

      if (this.dataService.getProperty('authSession')) {
        headers = headers.append('authJwt', this.dataService.getProperty('authSession').authJwt);
      }

      const response = await this.httpClient.delete(environment.api.url + environment.api.port + url + query, { headers, responseType: 'text' }).toPromise();
      this.logger.log('ApiService', 'deleteApi', response, 'debug');

      return response;

    } catch (error) {
      console.log(error);
      this.logger.log('ApiService', 'localDeleteApi', error.error, 'error');
      return;
    }
  }

}
