
import { Injectable } from '@angular/core';
import * as filestack from 'filestack-js';
import QrScanner from 'qr-scanner';

import { DataService } from './data.service';
import { LoggerService } from './logger.service';


@Injectable({ providedIn: 'root' })


export class FileService {

  constructor(private dataService: DataService) {}

  async openCamera(videoElem: any, cb: any) {
    try {

      const options = {
        returnDetailedScanResult: true,
      };

      const qrScanner = new QrScanner(videoElem, result => {
        qrScanner.stop();
        cb(result);
        return;
      });

      qrScanner.start();

      return qrScanner;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  closeCamera(qrScanner: any) {
    try {

      qrScanner.stop(qrScanner);

      qrScanner.destroy();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async openPicker(options) {
    try {

      const clientOptions = {
        security: {
          policy: this.dataService.getProperty('accountProfile') ? this.dataService.getProperty('accountProfile').sas.policy : this.dataService.getProperty('authSession').sas.policy,
          signature: this.dataService.getProperty('accountProfile') ? this.dataService.getProperty('accountProfile').sas.signature : this.dataService.getProperty('authSession').sas.signature
        }
      };

      const client = filestack.init(this.dataService.getProperty('accountProfile') ? this.dataService.getProperty('accountProfile').sas.key : this.dataService.getProperty('authSession').sas.key, clientOptions);

      client.picker(options).open();

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

  async timeBulkSort(newPosts: [any]) {
    try {

      var tempMasterFeed = [];

      if (this.dataService.getProperty('masterFeed')) {
        for (let i = 0; i < this.dataService.getProperty('masterFeed').length; i++) {
          tempMasterFeed.push(this.dataService.getProperty('masterFeed')[i]);
        }
      }

      var oldPostId = '';

      for (let i = 0; i < newPosts.length; i++) {

        const postId = newPosts[i]._id;

        if (oldPostId === postId) {
          continue;
        }

        if (oldPostId !== postId) {
          oldPostId = postId;
        }
        
        tempMasterFeed.push(newPosts[i]);
      }

      tempMasterFeed.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      tempMasterFeed = tempMasterFeed.reverse();

      this.dataService.setProperty('masterFeed', tempMasterFeed);

      return;

    } catch (error) {
      console.log(error);
      return;
    }
  }

}
